<div id="particles-js"></div>
<div class="d-lg-flex full justify-content-center">
  <div class="contents order-2 order-md-1">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-md-6">
          <div class="card border-0 shadow-sm rounded-lg">
            <div class="card-body p-4 py-5">
              <h3 class="text-center">
                <strong
                  ><img
                    src="../../../assets/images/logo-atlpay.svg"
                    style="width: 160px"
                    alt="logo-atlpay"
                /></strong>
              </h3>
              <p class="mb-4 font-weight-bold text-center">
                Sign in to continue
              </p>
              <form
                class="form-horizontal"
                [formGroup]="loginForm"
                (ngSubmit)="onSubmit()"
              >
                <div class="mb-3">
                  <label for="email">Email</label>
                  <input
                    type="email"
                    formControlName="emailId"
                    class="form-control"
                    id="email"
                    placeholder="Email"
                    [ngClass]="{
                      'is-invalid': submitted && f['emailId'].errors
                    }"
                  />
                  <div
                    *ngIf="submitted && f['emailId'].errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f['emailId'].errors['required']">
                      Email is required
                    </div>
                    <div *ngIf="f['emailId'].errors['email']">
                      Email must be a valid email address
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="password">Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input
                      [type]="password"
                      class="form-control"
                      formControlName="password"
                      placeholder="Enter password"
                      [ngClass]="{
                        'is-invalid': submitted && f['password'].errors
                      }"
                      aria-label="Password"
                      aria-describedby="password-addon"
                    />

                    <button
                      class="btn btn-light ms-0"
                      type="button"
                      (click)="onShowHideIcon()"
                      id="password-addon"
                    >
                      <i class="mdi mdi-eye-outline" *ngIf="!show"></i>
                      <i class="mdi mdi-eye-off-outline" *ngIf="show"></i>
                    </button>
                    <div
                      *ngIf="submitted && f['password'].errors"
                      class="invalid-feedback"
                    >
                      <span *ngIf="f['password'].errors['required']"
                        >Password is required</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="mt-5 d-flex align-items-center justify-content-between"
                >
                  <button class="btn btn-primary w-md" type="submit">
                    Log In
                  </button>
                  <span class="ml-auto"
                    ><a
                      routerLink="/password/forgot-password"
                      [state]="{ type: 'MERCHANT' }"
                      class="forgot-pass"
                      >Forgot Password?</a
                    ></span
                  >
                </div>
              </form>
              <ng-template
                #formError
                let-control="control"
                let-message="message"
                let-validation="validation"
              >
                <ng-container
                  *ngIf="
                    control.hasError(validation) &&
                    (control.dirty || control.touched)
                  "
                >
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <span role="alert">{{ message }}</span>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
            </div>
          </div>
          <div class="mt-5 text-center">
            <p>
              Don't have an account ?
              <a routerLink="/merchant/signUp" class="fw-medium text-primary">
                Signup now
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-primary-subtle">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p>Sign in to continue to ATLPay</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="../../../assets/images/profile-img.png"
                  alt=""
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <a href="">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img
                      src="../../../assets/images/logo.svg"
                      alt=""
                      height="20"
                    />
                  </span>
                </div>
              </a>
            </div>
            <div class="p-2">
              <form
                class="form-horizontal"
                [formGroup]="loginForm"
                (ngSubmit)="onSubmit()"
              >
                <div class="mb-3">
                  <label for="email">Email</label>
                  <input
                    type="email"
                    formControlName="emailId"
                    class="form-control"
                    id="email"
                    placeholder="Email"
                    [ngClass]="{
                      'is-invalid': submitted && f['emailId'].errors
                    }"
                  />
                  <div
                    *ngIf="submitted && f['emailId'].errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f['emailId'].errors['required']">
                      Email is required
                    </div>
                    <div *ngIf="f['emailId'].errors['email']">
                      Email must be a valid email address
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="password">Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input
                      [type]="password"
                      class="form-control"
                      formControlName="password"
                      placeholder="Enter password"
                      [ngClass]="{
                        'is-invalid': submitted && f['password'].errors
                      }"
                      aria-label="Password"
                      aria-describedby="password-addon"
                    />

                    <button
                      class="btn btn-light ms-0"
                      type="button"
                      (click)="onShowHideIcon()"
                      id="password-addon"
                    >
                      <i class="mdi mdi-eye-outline" *ngIf="!show"></i>
                      <i class="mdi mdi-eye-off-outline" *ngIf="show"></i>
                    </button>
                    <div
                      *ngIf="submitted && f['password'].errors"
                      class="invalid-feedback"
                    >
                      <span *ngIf="f['password'].errors['required']"
                        >Password is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="mt-3 d-grid">
                  <button class="btn btn-primary" type="submit">Log In</button>
                </div>
                <div class="mt-4 text-center">
                  <a
                    routerLink="/password/forgot-password"
                    [state]="{ type: 'MERCHANT' }"
                    class="text-muted"
                    ><i class="mdi mdi-lock mr-1"></i> Forgot your password?</a
                  >
                </div>
              </form>
              <ng-template
                #formError
                let-control="control"
                let-message="message"
                let-validation="validation"
              >
                <ng-container
                  *ngIf="
                    control.hasError(validation) &&
                    (control.dirty || control.touched)
                  "
                >
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <span role="alert">
                        {{ message }}
                      </span>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="mt-5 text-center">
          <p>
            Don't have an account ?
            <a routerLink="/merchant/signUp" class="fw-medium text-primary">
              Signup now
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div> -->

import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeywordsAndConstants } from 'src/core/keywords-and-constants';
import { AdminLoginService } from 'src/network/services/admin/admin-login.service';
import { LocalStorageService } from 'src/network/services/local-storage.service';
import { SideBarService } from 'src/network/services/sidebar.service';
import { DialogUtil } from 'src/utils/dialog-utils';
import { LoginRequest } from 'src/models/reqres/admin-login-request';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ErrorAction } from 'src/enums/error-action';
import { MessageType } from 'src/enums/message-type';
import { AccessControlCategory } from 'src/models/access-control.model';
import { GenericListResponse } from 'src/models/generic-list-response';
import { MerchantSignupService } from 'src/network/services/merchant/merchant-signup.service';

declare var particlesJS: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  submitted: any = false;
  error: any = '';
  returnUrl!: string;
  password: any;
  show = false;
  uuid: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private constants: KeywordsAndConstants,
    private _localStorageService: LocalStorageService,
    public _loginService: AdminLoginService,
    public _sideBarService: SideBarService,
    private dialog: DialogUtil,
    public _merchantService: MerchantSignupService
  ) {
    this._localStorageService.deviceInformation();
    if (
      localStorage.getItem(this.constants.LocalStorageToken) &&
      localStorage.getItem(this.constants.isLoginType)
    ) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {
    this.password = 'password';
  }

  async ngAfterViewInit(): Promise<void> {
    try {
      // Load the particles-js script dynamically
      await this.loadParticlesScript();

      // Initialize the particles.js configuration
      this.initParticles();
    } catch (error) {
      console.error('Failed to load particles-js:', error);
    }
  }

  private loadParticlesScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Check if the script is already loaded
      if (
        document.querySelector(
          'script[src="https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js"]'
        )
      ) {
        resolve();
        return;
      }

      // Dynamically load the particles.js script
      const script = document.createElement('script');
      script.src =
        'https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js';
      script.onload = () => resolve();
      script.onerror = () =>
        reject(new Error('Failed to load particles.js script.'));
      document.body.appendChild(script);
    });
  }

  private initParticles(): void {
    const targetElement = document.getElementById('particles-js');
    if (!targetElement) {
      console.error('Target element #particles-js not found.');
      return;
    }

    particlesJS('particles-js', {
      particles: {
        number: { value: 6, density: { enable: true, value_area: 800 } },
        color: { value: '#98aef2' },
        shape: {
          type: 'polygon',
          stroke: { width: 0, color: '#000' },
          polygon: { nb_sides: 6 },
          image: { src: 'img/github.svg', width: 100, height: 100 },
        },
        opacity: {
          value: 0.3,
          random: true,
          anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false },
        },
        size: {
          value: 160,
          random: false,
          anim: { enable: true, speed: 10, size_min: 40, sync: false },
        },
        line_linked: {
          enable: false,
          distance: 200,
          color: '#ffffff',
          opacity: 1,
          width: 2,
        },
        move: {
          enable: true,
          speed: 1,
          direction: 'none',
          random: false,
          straight: false,
          out_mode: 'out',
          bounce: false,
          attract: { enable: false, rotateX: 600, rotateY: 1200 },
        },
      },
      interactivity: {
        detect_on: 'canvas',
        events: {
          onhover: { enable: false, mode: 'grab' },
          onclick: { enable: false, mode: 'push' },
          resize: true,
        },
        modes: {
          grab: { distance: 400, line_linked: { opacity: 1 } },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: { distance: 200, duration: 0.4 },
          push: { particles_nb: 4 },
          remove: { particles_nb: 2 },
        },
      },
      retina_detect: true,
    });

    console.log('Particles.js initialized successfully.');
  }

  loginForm = this.formBuilder.group({
    emailId: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  get f() {
    return this.loginForm.controls;
  }

  onShowHideIcon() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

  onSubmit() {
    this.submitted = true;
    this.uuid = localStorage.getItem('uniqueIdentifierId');

    Object.assign(this.loginForm.value, {
      clientDevicePlatform: this._localStorageService.platform,
      deviceUserAgent: this._localStorageService.deviceUserAgent,
      uniqueIdentifierId: this.uuid,
    });

    const newLoginValue = new LoginRequest();
    newLoginValue.set(this.loginForm.value);
    if (this.loginForm.invalid) {
      return;
    } else {
      this._merchantService.login(newLoginValue).subscribe(
        (res) => {
          localStorage.setItem(
            this.constants.LocalStorageToken,
            JSON.stringify(res.token)
          );
          localStorage.setItem(
            this.constants.LocalStorageRefreshToken,
            JSON.stringify(res.refreshToken)
          );
          this._localStorageService.backUpToken = JSON.stringify(res.token);
          this._localStorageService.backUpRefreshToken = JSON.stringify(
            res.refreshToken
          );

          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(res.token);

          localStorage.setItem(this.constants.isLoginType, decodedToken.type);
          this._merchantService.user().subscribe((user: any) => {
            localStorage.setItem(
              this.constants.LocalStorageUserInfo,
              JSON.stringify(user)
            );
            this.getSideBarList();
          });
        },
        (error) => {
          if (error.errorAction != ErrorAction.HANDLED) {
            this.dialog.showToast(error.message, MessageType.ERROR);
          }
        }
      );
    }
  }

  getSideBarList() {
    this._sideBarService
      .getbyCategory()
      .subscribe((res: GenericListResponse<AccessControlCategory>) => {
        const filteredAccessControlCategories = res.data.filter((item: any) => {
          return item.shouldShow;
        });
        this.constants.byCategoryValue = filteredAccessControlCategories;
        this.constants.byCategoryValue.sort((a: any, b: any) => {
          return a.indexOrder - b.indexOrder;
        });
        this.constants.byCategoryValue.forEach((e: any) => {
          this.accessControl(e);
        });
        localStorage.setItem(
          this.constants.byCategory,
          JSON.stringify(this.constants.byCategoryValue)
        );
        this.router.navigate(['/dashboard']);
      });
  }

  accessControl(e: any) {
    var pageUrlFromAccessControlsList = '';
    e.accessControls.forEach((val: any) => {
      var method = val.method.substring(0, val.method.lastIndexOf('/') + 1);
      val.method = method.slice(0, -1);
      pageUrlFromAccessControlsList = val.method;
    });
    e.unreadCount = '';
    if (pageUrlFromAccessControlsList != '') {
      pageUrlFromAccessControlsList = pageUrlFromAccessControlsList.replace(
        /[A-Z]+(?![a-z])|[A-Z]/g,
        ($, ofs) => (ofs ? '-' : '') + $.toLowerCase()
      );
      e.pageUrl = pageUrlFromAccessControlsList;
    } else {
      e.pageUrl = e.pageUrl.replace(
        /[A-Z]+(?![a-z])|[A-Z]/g,
        ($: any, ofs: any) => (ofs ? '-' : '') + $.toLowerCase()
      );
    }
    if (e.subCategories.length > 0) {
      e.subCategories = e.subCategories.filter(
        (item: any) => item.shouldShow === true
      );
      e.subCategories.sort((a: any, b: any) => {
        return a.indexOrder - b.indexOrder;
      });
      e.subCategories.forEach((subCategoriesval: any) => {
        this.accessControl(subCategoriesval);
      });
    }
  }
}
